@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 0;
  margin: 0;
  font-size: 1.6rem;
  color: var(--blackColor);
  overflow-x: hidden;
}
:root {
  --primaryColor: #1a1a1a;
  --secondaryColor: #ef662f;
  --forWebsitePrimaryColor: #ff9115;
  --whiteColor: #ffffff;
  --blackColor: #000;
  --lightPrimaryColor: #363636;
  --BlueColor: #2d7392;
  --LightBg: #e6e6e6;
  --DarkRed: #7d131d;

  --SuccessColorBg: rgba(84, 214, 44, 0.16);
  --SuccessColor: #229a16;
  --DangerColorBg: rgba(255, 72, 66, 0.16);
  --DangerColor: #b72136;
  --yellowColor: #fcb200;
  --yellowColorBg: #fbf0d6;
  --primaryBadgeColor: #35bae9;
  --primaryBadgeBg: #e1f4fb;

  --lightThemeColor: #e1f4fb;
}

@mixin transition($x...) {
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}
a {
  color: var(--primaryColor);
  transition: 0.3s;
}
a,
a:hover,
a:focus,
a.active {
  text-decoration: none;
}
a:hover {
  color: var(--forWebsitePrimaryColor);
  font-weight: 600;
}

.btn:first-child:hover,
:not(.btn-check) + .btn:hover {
  color: unset;
  background-color: unset;
  border-color: unset;
}

.btn-outline {
  border: 2px solid var(--lightPrimaryColor);
  color: var(--primaryColor);
  padding: 10px 40px !important;
  font-weight: 600;
  font-size: 2rem;
  border-radius: 0;
  box-shadow: 4.91px 4.91px 0.61px rgb(2 2 2 / 25%);
  transition: 0.3s;
  display: inline-block;
  text-transform: uppercase;
  background-color: var(--whiteColor);
  &:hover {
    color: var(--BlueColor);
    border: 3px solid var(--BlueColor);
  }
  &:focus {
    background-color: var(--lightPrimaryColor);
    border-color: var(--lightPrimaryColor);
    color: var(--whiteColor);
    box-shadow: none;
  }
}
.button-sec {
  .btn-outline {
    border: 2px solid var(--lightPrimaryColor);
    color: var(--primaryColor);
    padding: 10px 40px !important;
    font-weight: 600;
    font-size: 2rem;
    border-radius: 0;
    box-shadow: 4.91px 4.91px 0.61px rgb(2 2 2 / 25%);
    transition: 0.3s;
    display: inline-block;
    text-transform: uppercase;
    background-color: var(--whiteColor);
    &:hover {
      background-color: var(--whiteColor);
      color: var(--forWebsitePrimaryColor);
      border: 3px solid var(--forWebsitePrimaryColor);
    }
  }
}

.section-title {
  h2 {
    font-size: 5.4rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
  }
  h3 {
    font-size: 4.2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
  }
  h3 + p {
    font-size: 2.4rem;
  }
  p, .pH_3 {
    font-size: 3.6rem;
    margin-bottom: 0;
    font-weight: 400;
    text-transform: unset;
  }
}

.container {
  max-width: 100%;
  padding: 0 15rem;
}
:not(.aha-header) > .container-fluid {
  padding: 0 30px;
}
.text-website-color {
  color: var(--forWebsitePrimaryColor);
}

.row {
  margin-left: -30px;
  margin-right: -30px;
}
.row > * {
  padding-left: 30px;
  padding-right: 30px;
}

.bg-e6e6e5 {
  background-color: var(--LightBg);
}

.bg-363636 {
  background-color: var(--lightPrimaryColor);
}
.main-content {
  min-height: calc(100vh - 243px);
  padding-bottom: 100px;
}
.mt-7 {
  margin-top: 7rem;
}
.dark-red-color {
  color: var(--DarkRed);
}
.light-orange-color {
  border-color: var(--forWebsitePrimaryColor);
  &:hover {
    border-color: var(--forWebsitePrimaryColor);
    color: var(--forWebsitePrimaryColor);
  }
}
.border-dark-red-color {
  border-color: var(--DarkRed);
  &:hover {
    border-color: var(--lightPrimaryColor);
    color: var(--blackColor);
  }
  &:focus {
    background-color: var(--DarkRed);
    border-color: var(--DarkRed);
    color: var(--whiteColor);
    box-shadow: none;
  }
}
.bg-secondaryColor {
  background-color: var(--secondaryColor);
}
.text-orange {
  color: var(--secondaryColor);
}

.banner-section {
  img {
    width: 100%;
    object-fit: cover;
    height: 80rem;
    object-position: center;
  }
}
.toggle-menu {
  display: none;
}

.banner-section {
  position: relative;
  h1 {
    position: absolute;
    left: 15rem;
    bottom: 15rem;
    font-size: 6.5rem;
    margin: 0;
    font-weight: 900;
    color: var(--whiteColor);
    text-shadow: 0 0px 10px rgb(0 0 0 / 75%);
    text-transform: uppercase;
  }
}

// RESPONSIVE CSS

@media (max-width: 1366px) {
  .section-title h2 {
    font-size: 4rem;
  }
  .section-title p {
    font-size: 2.4rem;
  }
  .section-title .pH_3 {
    font-size: 2.4rem;
  }
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
  .row > * {
    padding-left: 15px;
    padding-right: 15px;
  }
  .container {
    padding: 0 15px;
  }
  :not(.aha-header) > .container-fluid {
    padding: 0 15px;
  }
  .banner-section {
    h1 {
      left: 10rem;
      bottom: 15rem;
      font-size: 5.5rem;
    }
  }
}
@media (max-width: 1199px) {
  .mt-7 {
    margin-top: 3rem;
  }
}
@media (max-width: 991px) {
  .section-title {
    h2 {
      font-size: 3rem;
    }
    h3 {
      font-size: 2.6rem;
    }
    p, .pH_3 {
      font-size: 2.2rem;
    }
  }
  .btn-outline {
    font-size: 16px;
    padding: 10px 30px !important;
  }
  .mt-7 {
    margin-top: 4rem;
  }
  .toggle-menu {
    display: block;
  }
  .banner-section {
    img {
      min-height: calc(80vh - 10rem);
    }
    h1 {
      left: 15px;
      right: 15px;
      font-size: 4rem;
      bottom: 10rem;
    }
  }
}




// minheight about  set section 

.about_top_1{
  min-height: 1190px;
}

.about_video_height{
  min-height: 800px;
}
.about_mission_height{
  min-height: 1340px;
}
@media (max-width:1660px) {
  .about_top_1{
    min-height: 1130px;
  }
  .about_mission_height{
    min-height: 1205px;
  }
}
@media (max-width:1366px) {
  .about_top_1{
    min-height:630px;
  }
  
  .about_video_height{
    min-height: 550px;
  }
  .about_mission_height{
    min-height: 930px;
  }
}


.top-go_3{
  height: 90px;
  transform: translateY(130px);
  opacity: 0;
  position: relative;
  z-index: -99;
}

.top-go_4{
  height: 90px;
  transform: translateY(40px);
  opacity: 0;
  position: relative;
  z-index: -99;
}






// service section minHeight set

.min_height_ser_banner {
  min-height: 792px;
}
.min_height_ser_banner_content{
  min-height: 710px;
}
.min_height_ser_capiable{
  min-height: 815px;
}
.min_height_ser_onSite {
  min-height: 500px;
}
.min_height_service {
  min-height: 1080px;
}
.min_height_administive {
  min-height: 1760px;
}
.itSupp_ser_height {
  min-height: 1425px;
}
.exp_ser_minHeight {
  min-height: 1526px;
}
.test_ser_minheight {
  min-height: 503px;
}


@media (max-width:1660px) {
  .min_height_ser_banner {
    min-height: 690px;
  }
  .banner-section img{
    height: 690px;
  }
  .min_height_ser_capiable{
    min-height: 785px;
  }
  .min_height_ser_onSite {
    min-height: 503px;
  }
  .min_height_service {
    min-height: 1022px;
  }
  .min_height_administive {
    min-height: 1560px;
  }
}

@media (max-width:1639.98px) {
  .exp_ser_minHeight {
    min-height: 1460px;
  }
  .min_height_administive {
    min-height: 1540px;
  }
}
@media (max-width:1480px) {
  .min_height_administive{
    min-height: 1400px;
  }
  .min_height_service {
    min-height: 960px;
  }
}

@media (max-width:1440px) {
  .min_height_administive{
    min-height: 1320px;
  }
  .top-go_3#instructional-services {
    height: 65px;
  }
}

@media (max-width:1366px){
  .min_height_ser_banner {
    min-height: 500px;
  }
  .banner-section img {
    height: 500px;
  }
  .min_height_ser_banner_content {
     min-height: 430px;
  }
  .min_height_ser_capiable {
   min-height: 765px;
  }
  .min_height_ser_onSite {
     min-height: 470px;
  }
  .min_height_service {
    min-height: 1000px;
  }
  .min_height_administive {
   min-height: 1280px;
  }
  .exp_ser_minHeight {
    min-height: 1300px;
  }
  .test_ser_minheight {
    min-height: 420px;
  }
}

@media (max-width:1288px) {
  .min_height_service {
    min-height: 965px
  } 
  .min_height_administive {
    min-height: 1180px;
  }
  .itSupp_ser_height {
     min-height: 1440px; 
  }
  .exp_ser_minHeight {
    min-height: 1245px; 
  }
}

@media (max-width:1199.98px) {
  .about_top_1{
    min-height:580px;
  }
  .min_height_ser_banner {
    min-height: 410px; 
  }
  .banner-section img {
    height: 410px;
  }
  .min_height_ser_banner_content {
    min-height: 420px;
  }
  .min_height_ser_capiable {
    min-height: 710px;
  }
  .min_height_ser_onSite {
    min-height: 500px; 
  }
  .min_height_service {
    min-height: 900px;
  }
  .min_height_administive {
    min-height: 1000px;
  }
  .itSupp_ser_height {
   min-height: 1260px;
  }
  .exp_ser_minHeight {
    min-height: 1744px;
  }
  .test_ser_minheight {
    min-height: 450px;
  }
}

@media (max-width:991.98px) {
  .min_height_ser_banner {
    min-height: unset; 
  }
  .banner-section img {
    height: unset;
  }
  .min_height_ser_banner_content {
    min-height: unset;
  }
  .min_height_ser_capiable {
    min-height: unset;
  }
  .min_height_ser_onSite {
    min-height: unset; 
  }
  .min_height_service {
    min-height: unset;
  }
  .min_height_administive {
    min-height: unset;
  }
  .itSupp_ser_height {
   min-height: unset;
  }
  .exp_ser_minHeight {
    min-height: unset;
  }
  .test_ser_minheight {
    min-height: unset;
  }
  .top-go_3#instructional-services {
    height: 45px;
  }
}

@media (max-width:575.98px) {
  .services-design-section {
    padding-top: 0!important;
  }

  .top-go_4{
    height: 1px;
  }
  
}